.launch-page {
  display: flex;
  flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.description {
  margin-top: 80px;
    padding: 0 20px;
  text-align: center;
}
    .description h1 {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 20px;
    color: white;
      text-transform: uppercase;
      letter-spacing: 2px;
}
  
    .description p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 20px;
      color: white;
    }
  
    .social-icons a:not(:last-child) {
      margin-right: 20px;
    }
.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icons a {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .launch-page {
    height: 150vh;
  }

  .description {
    padding: 0 10px;
  }

  .social-icons {
    flex-wrap: wrap;
  }

  .social-icons a {
    margin: 10px 0;
  }
}